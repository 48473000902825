import AsyncSelect from "react-select/async";
import Header from "../../layout/Header";
import Sidenav from "../../layout/Sidenav";
import { AdvertiseModel } from "./AdvertiseModel";

const AdvertiseView: React.FC = () => {
    const advertiseModel = AdvertiseModel();
    return (
        <div className="page-container" id="sideNavigation">
            <Sidenav />

            <div className="main-content">
                <Header />

                <div className="main-content-inner">
                    <div className="row">

                        <div className="col-md-12 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" placeholder="Search..."
                                                onChange={(event) => advertiseModel.searchPackage(event)} />
                                        </div>
                                        <div className="col-md-6 mb-3 text-right">
                                            <button type="button" className="btn btn-primary btn-flat btn-lg" data-toggle="modal" data-target="#createAdvertisement">Create Advertisement</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="text-uppercase bg-secondary">
                                                <tr className="text-white">
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Budget</th>
                                                    <th scope="col">From </th>
                                                    <th scope="col">To </th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Approve Status</th>

                                                    <th scope="col">action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    advertiseModel.lstAdvertise && advertiseModel.lstAdvertise.map((d: any, index: number) => (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{d.advertiseName}</td>
                                                            <td>${d.Budget}</td>
                                                            <td>{d.FromDate}</td>
                                                            <td>{d.ToDate}</td>
                                                            <td>{d.status}</td>
                                                            <td>{d.ApproveStatus}</td>
                                                            <td>
                                                                {
                                                                    d.ApproveStatus != 'Approved' ?
                                                                        <><i className="ti-pencil mx-2" data-toggle="modal" data-target="#createAdvertisement" onClick={(e) => advertiseModel.editAdvertise(d)}></i> </> :
                                                                        <></>
                                                                }
                                                                <i className="ti-trash text-danger" onClick={(e) => advertiseModel.deleteAdvertise(d)}></i>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 text-right">
                                            <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                                                <ul className="pagination float-right">
                                                    <li className={advertiseModel.currentPage==1 ? 'paginate_button page-item previous disabled' : 'paginate_button page-item previous' }
                                                        id="dataTable_previous" onClick={advertiseModel.handlePreviousPage}>
                                                        <a href="#" aria-controls="dataTable" data-dt-idx="0" className="page-link">Previous</a>
                                                    </li>
                                                    {
                                                        advertiseModel.pages.map((pageNumber) => (
                                                            <li className= {advertiseModel.currentPage==pageNumber ? 'paginate_button page-item active' : 'paginate_button page-item'} 
                                                                key={pageNumber} onClick={(e)=>advertiseModel.selectPage(pageNumber)}>
                                                                <a  aria-controls="dataTable" data-dt-idx="1" className="page-link">{pageNumber}</a>
                                                            </li>
                                                        ))
                                                    }
                                                    {
                                                        advertiseModel.pages && advertiseModel.tempPage.length > 5 ? 
                                                        <><li className="paginate_button page-item next" id="dataTable_next"><a href="" className="page-link">...</a></li></>
                                                        : <></>
                                                    }

                                                    {/* {advertiseModel.tempPage.length} - {advertiseModel.currentPage} */}
                                                    
                                                    <li className={(advertiseModel.tempPage.length-1) == advertiseModel.currentPage ? 'paginate_button page-item next disabled' :'paginate_button page-item next'} //"paginate_button page-item next" 
                                                        id="dataTable_next" onClick={advertiseModel.handleNextPage}>
                                                        <a aria-controls="dataTable" data-dt-idx="3" className="page-link">Next</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade show" id="createAdvertisement">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="row" style={{ 'display': 'contents' }}>
                                <div className="col-md-10 p-0">
                                    <h6 className="modal-title">Create Advertisement</h6>
                                    <ul className="breadcrumbs pull-left">
                                        <li><a >Details</a></li>
                                        <li><a>Package Details</a></li>
                                        <li>Preview</li>
                                    </ul>
                                </div>
                                <div className="col-md-2 ">
                                    <button type="button" id="closeModel" className="close" data-dismiss="modal" onClick={advertiseModel.reset}><span>×</span></button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div >

                                <div className="row">
                                    {/* <div className="col-md-12 mb-3">
                                        <h4 className="header-title m-0">Create Advertisement </h4>
                                        <ul className="breadcrumbs pull-left">
                                            <li><a >Details</a></li>
                                            <li><a>Package Details</a></li>
                                            <li>Preview</li>
                                        </ul>
                                    </div> */}
                                    <div className="col-md-12 mb-3">
                                        <div className="progress" style={{ height: '10px' }}>
                                            <div className="progress-bar" role="progressbar" style={{ width: `${advertiseModel.progress}%` }} ></div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    advertiseModel.activeTab == 'Details' ?
                                        (
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <input type="text" className={advertiseModel.detailsSubmitted && advertiseModel.advertiseDetails.advertiseName == '' ?
                                                                'form-control invalid' : 'form-control'} onChange={advertiseModel.detailsChange}
                                                                name="advertiseName" value={advertiseModel.advertiseDetails.advertiseName} placeholder="Enter name" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>From date</label>
                                                            <input type="date" className={advertiseModel.detailsSubmitted && advertiseModel.advertiseDetails.startValue == '' ?
                                                                'form-control invalid' : 'form-control'} onChange={advertiseModel.detailsChange}
                                                                min={advertiseModel.minDate}
                                                                name="startValue" value={advertiseModel.advertiseDetails.startValue} placeholder="date" />

                                                            {advertiseModel.detailsSubmitted && advertiseModel.advertiseDetails.startValue > advertiseModel.advertiseDetails.endValue ?
                                                                (
                                                                    <>
                                                                        <label className="text-danger">Invalid Date</label>
                                                                    </>
                                                                ) :

                                                                (
                                                                    <>

                                                                    </>
                                                                )
                                                            }


                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>To date</label>
                                                            <input type="date" className={advertiseModel.detailsSubmitted && advertiseModel.advertiseDetails.endValue == '' ?
                                                                'form-control invalid' : 'form-control'} onChange={advertiseModel.detailsChange}
                                                                min={advertiseModel.minDate}
                                                                name="endValue" value={advertiseModel.advertiseDetails.endValue} placeholder="date" />

                                                            {advertiseModel.detailsSubmitted && advertiseModel.advertiseDetails.endValue < advertiseModel.advertiseDetails.startValue ?
                                                                (
                                                                    <>
                                                                        <label className="text-danger">Invalid Date</label>
                                                                    </>
                                                                ) :

                                                                (
                                                                    <>

                                                                    </>
                                                                )
                                                            }

                                                        </div>
                                                    </div>

                                                    {
                                                        advertiseModel.detailsSubmitted && advertiseModel.advertiseDetails.startValue == advertiseModel.advertiseDetails.endValue ?
                                                            <>
                                                                <div className="col-md-12">
                                                                    <label className="text-danger">Dates Should Not Be Same.</label>
                                                                </div>
                                                            </> : ''
                                                    }

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Ads Url</label>
                                                            <input type="text" className="form-control" onChange={advertiseModel.detailsChange}
                                                                name="RedirectUrl" value={advertiseModel.advertiseDetails.RedirectUrl} placeholder="Enter url" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-10">
                                                        <div className="form-group">
                                                            <label>Upload Image  (1920 x 1080)</label>
                                                            {/* <input type="file" className="form-control" name="Image" value={advertiseModel.advertiseDetails.Image} placeholder="Upload image" /> */}
                                                            <div className={advertiseModel.detailsSubmitted && advertiseModel.imageSrc == '' ? 'input-group mb-3 invalid' : 'input-group mb-3'}>
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">Upload</span>
                                                                </div>
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input" name="Image"
                                                                        onChange={(e: any) => advertiseModel.uploadImage(e)} />
                                                                    <label className="custom-file-label" >Choose file</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2 my-auto">
                                                        <img src={advertiseModel.imageSrc} className="w-100" />
                                                    </div>

                                                    <div className="col-md-12">
                                                        <label>Description</label>
                                                        <textarea className="form-control" name="description" value={advertiseModel.advertiseDetails.description}
                                                            rows={4} onChange={advertiseModel.detailsChange}></textarea>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary mt-4 pr-4 pl-4 float-right" onClick={() => advertiseModel.openTab('PackageSelection')}>Next</button>
                                            </>
                                        )
                                        :
                                        (
                                            advertiseModel.activeTab == 'PackageSelection' && advertiseModel.validDetails == false ?
                                                (<>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <label>Package</label>
                                                            <AsyncSelect
                                                                isClearable
                                                                isSearchable
                                                                cacheOptions
                                                                defaultOptions={advertiseModel.packageOptions}
                                                                value={advertiseModel.packageOptions.length > 0 ? (advertiseModel.packageOptions.find((option: any) => option.value == advertiseModel.advertiseDetails.PackageID) || null) : null}
                                                                loadOptions={(inputValue, callback) => {
                                                                    callback(advertiseModel.packageOptions);
                                                                }}
                                                                onChange={advertiseModel.packageChange}
                                                            />

                                                        </div>
                                                        <div className="col-md-12">
                                                            <label>Select Budget (${advertiseModel.packageRanges.RangeFrom} to ${advertiseModel.packageRanges.RangeTo}) <b className="text-primary">${advertiseModel.advertiseDetails.Budget}</b></label>
                                                            <input type="range" id="vol" name="Budget"
                                                                min={advertiseModel.packageRanges.RangeFrom}
                                                                max={advertiseModel.packageRanges.RangeTo} className="form-control p-0"
                                                                onChange={(e) => advertiseModel.onChange(e.target.value)}
                                                                value={advertiseModel.advertiseDetails.Budget}
                                                            />
                                                            {/* value={advertiseModel.advertiseDetails.Budget} */}
                                                        </div>
                                                        <div className="col-md-12">
                                                            <button className="btn btn-primary mt-4 pr-4 pl-4 float-right" onClick={() => advertiseModel.openTab('Preview')}>Preview</button>
                                                            <button className="btn btn-light mt-4 pr-4 pl-4 float-right mx-3" onClick={() => advertiseModel.back('Details')}>Back</button>
                                                        </div>
                                                    </div>
                                                </>
                                                )

                                                : (advertiseModel.activeTab == 'Preview' ?
                                                    (
                                                        <>
                                                            <div className="row">
                                                                <div className="col-md-12 mb-3">
                                                                    <h5>{advertiseModel.advertiseDetails.advertiseName}</h5>
                                                                    <p>{advertiseModel.advertiseDetails.description}</p>
                                                                    <p><b>Budget : </b> ${advertiseModel.advertiseDetails.Budget}</p>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <img src={advertiseModel.imageSrc} className="w-100 advertise_image" />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <button className="btn btn-primary mt-4 pr-4 pl-4 float-right" onClick={() => advertiseModel.openTab('Thanks')}>Pay</button>
                                                                    <button className="btn btn-light mt-4 pr-4 pl-4 float-right mx-3" onClick={() => advertiseModel.openTab('PackageSelection')}>Back</button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )

                                                    : (advertiseModel.activeTab == 'Thanks' ?
                                                        (<>
                                                            <div className="row">
                                                                <div className="col-md-2 mx-auto mb-3">
                                                                    <img src="assets/images/svg/tick-mark-icon.svg" alt="" />
                                                                </div>
                                                                <div className="col-md-12 text-center">
                                                                    <h3>Add Created Successfully.</h3>
                                                                    <p>Your advertisement is currently under review. We will notify you as soon as it has been approved.</p>
                                                                    <button className="btn btn-primary mt-3" onClick={advertiseModel.closeModel}>Ok</button>
                                                                </div>
                                                            </div>
                                                        </>)
                                                        : '')))
                                }
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdvertiseView;