import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../store/Page.Actions";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails :any = useSelector((state: AppState) => state.storeData.loggedUser);

    const  logout  = () =>{
        debugger;
        dispatch({
            type: ActionType.STORE,
            payload: {
                storeData:[],
                viewName:'loggedUser',
            },
          });
        navigate('/login');
    }

    const close = () =>{
        debugger;
        var classes = document.getElementById('sideNavigation')?.getAttribute('class');
       var collapse =  document.getElementById('sideNavigation');
       debugger;
       if(classes == 'page-container'){
        collapse?.classList.add('sbar_collapsed');
       }else{
        collapse?.classList.remove('sbar_collapsed');
       }
       
    }

    return (
        <>
            {/* <div className="main-content" > */}
                {/* <div className="header-area">
                    <div className="row align-items-center">

                        <div className="col-md-6 col-sm-8 clearfix">
                            <div className="nav-btn pull-left" >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="search-box pull-left">
                                <form action="#">
                                    <input type="text" name="search" placeholder="Search..." />
                                    <i className="ti-search"></i>
                                </form>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-4 clearfix">
                            <ul className="notification-area pull-right">
                                <li id="full-view"><i className="ti-fullscreen"></i></li>
                                <li id="full-view-exit"><i className="ti-zoom-out"></i></li>
                                <li className="dropdown">
                                    <i className="ti-bell dropdown-toggle" data-toggle="dropdown">
                                        <span>2</span>
                                    </i>
                                    <div className="dropdown-menu bell-notify-box notify-box">
                                        <span className="notify-title">You have 3 new notifications <a href="#">view all</a></span>
                                        <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '435px' }}>
                                            <div className="nofity-list" style={{ overflow: 'hidden', width: 'auto', height: '435px' }}>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb"><i className="ti-key btn-danger"></i></div>
                                                    <div className="notify-text">
                                                        <p>You have Changed Your Password</p>
                                                        <span>Just Now</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb"><i className="ti-comments-smiley btn-info"></i></div>
                                                    <div className="notify-text">
                                                        <p>New Commetns On Post</p>
                                                        <span>30 Seconds ago</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb"><i className="ti-key btn-primary"></i></div>
                                                    <div className="notify-text">
                                                        <p>Some special like you</p>
                                                        <span>Just Now</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb"><i className="ti-comments-smiley btn-info"></i></div>
                                                    <div className="notify-text">
                                                        <p>New Commetns On Post</p>
                                                        <span>30 Seconds ago</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb"><i className="ti-key btn-primary"></i></div>
                                                    <div className="notify-text">
                                                        <p>Some special like you</p>
                                                        <span>Just Now</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb"><i className="ti-key btn-danger"></i></div>
                                                    <div className="notify-text">
                                                        <p>You have Changed Your Password</p>
                                                        <span>Just Now</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb"><i className="ti-key btn-danger"></i></div>
                                                    <div className="notify-text">
                                                        <p>You have Changed Your Password</p>
                                                        <span>Just Now</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="slimScrollBar" style={{ background: 'rgb(0, 0, 0)', width: '7px', position: 'absolute', top: '0px', opacity: '0.4', display: 'block', borderRadius: '7px', zIndex: 99, right: '1px', height: '295.203px' }}></div>
                                            <div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', background: 'rgb(51, 51, 51)', opacity: '0.2', zIndex: 90, right: '1px', }}></div>
                                        </div>
                                    </div>
                                </li>
                                <li className="dropdown">
                                    <i className="fa fa-envelope-o dropdown-toggle" data-toggle="dropdown"><span>3</span></i>
                                    <div className="dropdown-menu notify-box nt-enveloper-box">
                                        <span className="notify-title">You have 3 new notifications <a href="#">view all</a></span>
                                        <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '435px' }}>
                                            <div className="nofity-list" style={{ overflow: 'hidden', width: 'auto', height: '435px' }}>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb">
                                                        <img src="assets/images/author/author-img1.jpg" alt="image" />
                                                    </div>
                                                    <div className="notify-text">
                                                        <p>Aglae Mayer</p>
                                                        <span className="msg">Hey I am waiting for you...</span>
                                                        <span>3:15 PM</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb">
                                                        <img src="assets/images/author/author-img2.jpg" alt="image" />
                                                    </div>
                                                    <div className="notify-text">
                                                        <p>Aglae Mayer</p>
                                                        <span className="msg">When you can connect with me...</span>
                                                        <span>3:15 PM</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb">
                                                        <img src="assets/images/author/author-img3.jpg" alt="image" />
                                                    </div>
                                                    <div className="notify-text">
                                                        <p>Aglae Mayer</p>
                                                        <span className="msg">I missed you so much...</span>
                                                        <span>3:15 PM</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb">
                                                        <img src="assets/images/author/author-img4.jpg" alt="image" />
                                                    </div>
                                                    <div className="notify-text">
                                                        <p>Aglae Mayer</p>
                                                        <span className="msg">Your product is completely Ready...</span>
                                                        <span>3:15 PM</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb">
                                                        <img src="assets/images/author/author-img2.jpg" alt="image" />
                                                    </div>
                                                    <div className="notify-text">
                                                        <p>Aglae Mayer</p>
                                                        <span className="msg">Hey I am waiting for you...</span>
                                                        <span>3:15 PM</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb">
                                                        <img src="assets/images/author/author-img1.jpg" alt="image" />
                                                    </div>
                                                    <div className="notify-text">
                                                        <p>Aglae Mayer</p>
                                                        <span className="msg">Hey I am waiting for you...</span>
                                                        <span>3:15 PM</span>
                                                    </div>
                                                </a>
                                                <a href="#" className="notify-item">
                                                    <div className="notify-thumb">
                                                        <img src="assets/images/author/author-img3.jpg" alt="image" />
                                                    </div>
                                                    <div className="notify-text">
                                                        <p>Aglae Mayer</p>
                                                        <span className="msg">Hey I am waiting for you...</span>
                                                        <span>3:15 PM</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="slimScrollBar" style={{ background: 'rgb(0, 0, 0)', width: '7px', position: 'absolute', top: '0px', opacity: '0.4', display: 'block', borderRadius: '7px', zIndex: 99, right: '1px', height: '238.019px' }}></div>
                                            <div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', background: 'rgb(51, 51, 51)', opacity: '0.2', zIndex: 90, right: '1px' }}></div>
                                        </div>
                                    </div>
                                </li>
                                <li className="settings-btn">
                                    <i className="ti-settings"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}
                
                <div className="page-title-area">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="breadcrumbs-area clearfix">
                            <div className="nav-btn pull-left" onClick={close}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                                {/* <h4 className="page-title pull-left">Dashboard</h4>
                                <ul className="breadcrumbs pull-left">
                                    <li><a href="index.html">Home</a></li>
                                    <li><span>Invoice</span></li>
                                </ul> */}
                            </div>
                        </div>
                        <div className="col-sm-6 clearfix">
                            <div className="user-profile pull-right">
                                {/* <img className="avatar user-thumb" src="assets/images/author/avatar.png" alt="avatar" /> */}
                                <h4 className="user-name dropdown-toggle" data-toggle="dropdown">{userDetails.FirstName} {userDetails.LastName}<i className="fa fa-angle-down"></i></h4>
                                <div className="dropdown-menu">
                                    <a className="dropdown-item" onClick={logout}>Log Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </>
    )
}

export default Header;