import Header from "../../layout/Header";
import Sidenav from "../../layout/Sidenav";
import { PackageModel } from "./PackageModel";

const PackageView: React.FC = () => {
    const packageModel = PackageModel()
    return (
        <>
            <div className="page-container">
                <Sidenav />
                <div className="main-content">
                    <Header />
                    <div className="main-content-inner">
                        <div className="row">
                            <div className="col-md-8 mt-5">
                                <div className="card">
                                    <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="text-uppercase bg-secondary">
                                                <tr className="text-white">
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Range</th>
                                                    <th scope="col">Validity Days</th>
                                                    <th scope="col">action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    packageModel.lstPackages && packageModel.lstPackages.map((d:any,index:number)=>(
                                                    <tr key={index}>
                                                        <th scope="row">{index+1}</th>
                                                        <td>{d.Name}</td>
                                                        <td>${d.RangeFrom} - ${d.RangeTo}</td>
                                                        <td>{d.ValidDays}</td>
                                                        <td>
                                                            <i className="ti-pencil mx-2" onClick={(e)=>packageModel.editPackage(d)}></i> 
                                                            <i className="ti-trash text-danger" onClick={(e)=>packageModel.deletePackage(d)}></i>
                                                        </td>
                                                    </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mt-5">
                                <div className="card">
                                    <div className="card-body">

                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <h4 className="header-title m-0">Create Package </h4>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input type="text"  className={packageModel.submitted && packageModel.adPackage.Name == ''? 
                                                    'form-control invalid' : 'form-control'} onChange={packageModel.packageChange}
                                                    name="Name" value={packageModel.adPackage.Name} placeholder="Enter name" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Range From</label>
                                                    <input type="number" className={packageModel.submitted && packageModel.adPackage.RangeFrom == 0? 
                                                    'form-control invalid' : 'form-control'} onChange={packageModel.packageChange}
                                                    name="RangeFrom" value={packageModel.adPackage.RangeFrom} placeholder="Range from" />

                                                    {packageModel.submitted && packageModel.adPackage.RangeFrom > packageModel.adPackage.RangeTo ? 
                                                    <label  className="text-danger">Is Greater Than Range To</label> :
                                                    ''}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Range To</label>
                                                    <input type="number" className={packageModel.submitted && packageModel.adPackage.RangeTo == 0? 
                                                    'form-control invalid' : 'form-control'} onChange={packageModel.packageChange}
                                                    name="RangeTo" value={packageModel.adPackage.RangeTo} placeholder="Range to" />
                                                    {packageModel.submitted && packageModel.adPackage.RangeTo < packageModel.adPackage.RangeFrom ? 
                                                    <label  className="text-danger">Is Less Than Range From</label> :
                                                    ''}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Valid Days</label>
                                                    <input type="number" className={packageModel.submitted && packageModel.adPackage.ValidDays == 0? 
                                                    'form-control invalid' : 'form-control'} onChange={packageModel.packageChange}
                                                    name="ValidDays" value={packageModel.adPackage.ValidDays} placeholder="Range to" />
                                                </div>
                                            </div>

                                            <div className="col-md-12 mb-3">
                                                <label>Description</label>
                                                <textarea className="form-control" name="Description" value={packageModel.adPackage.Description} rows={4}  onChange={packageModel.packageChange}></textarea>
                                            </div>

                                            <div className="col-md-12 text-right">
                                                <button className="btn btn-primary float-end" onClick={packageModel.savePackage}>Save</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PackageView;