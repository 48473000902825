import { useSelector } from "react-redux";
import { Api } from "./Api";
import { AppState } from "../store/Page.Actions";

export function DatabaseCalling (){
    const api = Api()
    // const userDetails = useSelector((state: AppState) =>
    //     {
    //       return  typeof(state.storeData.AdminLoginStored)!='undefined'?state.storeData.AdminLoginStored:null;
    //     });

    const userDetails :any = useSelector((state: AppState) => state.storeData.loggedUser);

    // AppState
    
    const signUp = async (details: any) => {
        // debugger;
        // $("#loaderParent").show();
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'businessuserregister', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                // body: details
                body:  (details)
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const login = async (details: any) =>{
        // debugger;
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'viewbusinessuser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body:  (details)
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const savePackage = async (details: any) =>{
        // debugger;
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'savepackage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.Token}`,
                    'Issuer': 'ChartTwits'
                },
                body:  (details)
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const viewPackages = async (details:any) =>{
        // debugger;
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'viewpackages', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                     'Authorization': `Bearer ${userDetails.Token}`,
                     'Issuer': 'ChartTwits'
                },
                body:  (details)
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deletePackage = async (packageData:any) =>{
        // debugger;
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'deletepackage',{
                method: 'POST',
                headers: ({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.Token}`,
                    'Issuer': 'ChartTwits'
                }),
                body:  packageData
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const deleteAdvertise = async (advertiseData:any) =>{
        // debugger;
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'deleteadvertise',{
                method: 'POST',
                headers: ({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.Token}`,
                    'Issuer': 'ChartTwits'
                }),
                body:  advertiseData
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    // advertisement

    const saveAdvertisement = async (details: any) =>{
        // debugger;
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'saveadvertise', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.Token}`,
                    'Issuer': 'ChartTwits'
                },
                body:  (details)
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const viewAdvertisements = async (details:any) =>{
        // debugger;
        try {
            // debugger;
            const response = await fetch(api.APIUrl + 'getadvertisementsadmin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDetails.Token}`,
                    'Issuer': 'ChartTwits'
                },
                body:  details
            })
            // debugger;
            const responseData: any = await response.json();
            // debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const uploadImage = async (formData: any) => {
        //// debugger;
        $("#loaderParent").show();
        try {
            //// debugger;
            const response = await fetch(api.APIUrl  + 'Images', {
                method: 'POST',
                body: formData,
            });
            //// debugger;
            if (response.ok) {
                //// debugger;
                const responseData = await response.json();
                $("#loaderParent").hide();
                return responseData;
            } else {
                console.error('Failed to upload image. Server returned:', response.status);
            }
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    return{signUp,login,savePackage,viewPackages,deletePackage,saveAdvertisement,uploadImage,viewAdvertisements,deleteAdvertise}
}