import { useSelector } from "react-redux";
import { LoginModel } from "./LoginModel";
import { AppState } from "../../store/Page.Actions";

const LoginView: React.FC = () => {
    const Loginmodel = LoginModel();


    return (
        <div className="app">
            <div className="login-area l">
                <div className="container">
                    <div className="login-box ptb--100">
                        <div className="login_form">
                            <div className="login-form-head">
                                <h4>Sign In</h4>
                                <p>Start managing your advertisement.</p>
                            </div>
                            <div className="login-form-body">
                                <div className="form-gp">
                                    <label >Email address</label>
                                    <input type="email" name="Email" onChange={Loginmodel.valueChange}
                                    className={(Loginmodel.login.Email) == '' && (Loginmodel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-email"></i>
                                    {
                                        Loginmodel.submitted && !Loginmodel.isValidEmail(Loginmodel.login.Email) ?
                                        <>
                                        <div className="text-danger">Invalid Email</div>
                                        </> : <></>
                                    }
                                </div>
                                <div className="form-gp">
                                    <label >Password</label>
                                    <input type="password" name="Password"  onChange={Loginmodel.valueChange}
                                    className={(Loginmodel.login.Password) == '' && (Loginmodel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-eye"></i>
                                    <div className="text-danger"></div>
                                </div>
                                <div className="row mb-4 rmber-area">
                                    <div className="col-12 text-right">
                                        <a href="#">Forgot Password?</a>
                                    </div>
                                </div>
                                <div className="submit-btn-area">
                                    <button id="form_submit" onClick={Loginmodel.signin}>Submit <i className="ti-arrow-right"></i></button>
                                </div>
                                <div className="form-footer text-center mt-5">
                                    <p className="text-muted">Don't have an account? <a href="sign-up">Sign up</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginView;