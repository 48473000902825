import { useLocation } from "react-router-dom";

const Sidenav: React.FC = () => {

    const location = useLocation();
    debugger;
    console.log(location.pathname);
    
    return (
        <div className="sidebar-menu">
            <div className="sidebar-header">
                <div className="logo">
                    <a href="#!"><h2 className="text-light">CTA</h2></a>
                </div>
            </div>
            <div className="main-menu">
                <div className="slimScrollDiv" style={{ position: 'relative', overflow: 'hidden', width: 'auto', height: '799px' }}>
                    <div className="menu-inner" style={{ overflow: 'hidden', width: 'auto', height: '799px' }}>
                        <nav>
                            <ul className="metismenu" id="menu">
                                {/* <li className={location.pathname == '/package' ? 'active' : '' } ><a href="package"><i className="ti-receipt"></i> <span>Package</span></a></li> */}
                                {/* <li ><a href="package"><i className="ti-receipt"></i> <span>Create Advertise</span></a></li> */}
                                <li className={location.pathname == '/create-advertise' ? 'active' : '' }><a href="create-advertise" aria-expanded="false"><i className="ti-receipt"></i> <span>Advertisement</span></a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="slimScrollBar" style={{ background: 'rgb(0, 0, 0)', width: '7px', position: 'absolute', top: '0px', opacity: 0.4, display: 'none', borderRadius: '7px', zIndex: 99, right: '1px', height: '799px' }}></div>
                    <div className="slimScrollRail" style={{ width: '7px', height: '100%', position: 'absolute', top: '0px', display: 'none', borderRadius: '7px', background: 'rgb(51, 51, 51)', opacity: 0.2, zIndex: 90, right: '1px' }}></div></div>
            </div>
        </div>
    )
}

export default Sidenav;