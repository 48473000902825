import { useEffect, useState } from "react";
import { DatabaseCalling } from "../../services/DatabaseCalling";
import { EncryptDecrypt } from "../../services/EncryptDecrypt";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


export function SignupModel(){

    const dbCalling = DatabaseCalling();
    const navigate = useNavigate();
    const ed = EncryptDecrypt();
    const [form ,setForm] = useState({
        ID:0,
        FirstName:'',
        LastName:'',
        Email:'',
        Organisation:'',
        ContactNo:'',
        Password:'',
        ConfirmPassword:'',
    });
    const [submitted,setSubmit] = useState(false);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    const valueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            debugger;
            if(e.target.type == "number"){
                debugger;
                let number = value.replace('.','');
                debugger;
                setForm((prev) => ({ ...prev, ContactNo: number }));
            }else{
                setForm((prev) => ({ ...prev, [name]: value }));
            }
            
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setForm((prev) => ({ ...prev, [name]: value }));
        }
    }


    const signUp = async () =>{
        debugger;
        setSubmit(true);
        let validatePassword ;
        let validateEmail;
        if( form.Password != '' && form.ConfirmPassword!='' && form.Password != form.ConfirmPassword){
            validatePassword = true;
        }else{
            validatePassword = false;
        }

        if(isValidEmail(form.Email)){
            validateEmail = false;
        }else{
            validateEmail = true;
        }

        debugger;
        if(form.FirstName !='' && form.LastName !='' && form.Email !='' && form.ContactNo!='' && !validatePassword && !validateEmail){
            debugger;
             let data = JSON.stringify(form);
            let signUpdata = ed.encryptData(data);
            const responseData: any = await  dbCalling.signUp(signUpdata);
            if(responseData[0].dbResult > 0){
                reset();
                Swal.fire("Success", "Registered Successfully", "success");
                navigate('/login',{replace:true});
            }else if(responseData[0].dbResult == -3){
                Swal.fire("User Already Exist!", "", "info");
            }
            
        }
    }

    const isValidEmail = (email:any) =>{
        debugger;
        return emailRegex.test(email);
    }


    const login = ()=>{
        debugger;
        navigate('login');
    }

    const reset = () =>{
        setSubmit(false);
        setForm({
            ID:0,
            FirstName:'',
            LastName:'',
            Email:'',
            Organisation:'',
            ContactNo:'',
            Password:'',
            ConfirmPassword:'',
        })
    }

    useEffect(()=>{
    },[form])

    return{
        login,valueChange,signUp,isValidEmail
        
        // states
        ,form,submitted
    };
}