import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { DatabaseCalling } from "../../services/DatabaseCalling";
import { EncryptDecrypt } from "../../services/EncryptDecrypt";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { AppState } from "../../store/Page.Actions";
import { format, formatDate } from "date-fns";
import { Api } from "../../services/Api";
import { debug } from "console";

export function AdvertiseModel() {
    const dbCalling = DatabaseCalling();
    const ed = EncryptDecrypt();
    const api  = Api();
    const navigate = useNavigate();

    const [activeTab, setActive] = useState('');
    const [selectedTab, setTab] = useState('');
    const [packages, setPackages] = useState([]);

    const [progress, setProgress] = useState(0);
    const [detailsSubmitted, setDetailsSubmit] = useState(false);
    const [validDetails, setDetailsValidation] = useState(false);

    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState('');
    const [minDate, setMinDate] = useState<any | null>(null);
    const userDetails: any = useSelector((state: AppState) => state.storeData.loggedUser);

    const [lstAdvertise,setLstAdvertise] = useState([]);
    const [templstAdvertise,setTempLstAdvertise] = useState([]);

    const [advertiseDetails, setAdvertiseDetails] = useState({
        ID: 0,
        advertiseName: '',
        ImageName: '',
        Image: '',
        description: '',
        startValue: '',
        endValue: '',
        RedirectUrl: '',
        PackageID: 0,
        Pincode: 0,
        Budget: 0
    })

    const [packageRanges, setPackageRange] = useState({
        RangeFrom: 0,
        RangeTo: 0
    })

    const packageOptions = packages.map((pkg: any) => ({
        value: pkg.ID,
        label: `${pkg.Name}: $${pkg.RangeFrom} - $${pkg.RangeTo}`,
    }));

    const [lstMerchantDrp, setMerchantDrp] = useState<{ label: string; value: string }[] | []>([]);

    // pegination
    const [pageLimit,setPageLimit] = useState(10);
    const [pages,setPages] =  useState([]);
    const [tempPage,setTempPages] = useState([]);
    const [peginationLimit,setPeginationLimit] = useState(3);
    const [currentPage,setCurrentPage] = useState(0);
    const [startIndex,setStartIndex] = useState(0);
    const [endIndex,setEndIndex] = useState(0);

    const signup = () => {
        // debugger;
        navigate('/sign-up');
    }

    const openTab = async (d: any) => {
        // debugger;
        setTab(d);
        if (d == 'PackageSelection') {
            // debugger;
            checkDetails();
        } else if (d == 'Preview') {
            setActive(d)
        } else if (d = 'Thanks') {
            saveAdvertise(d)
        }
    }

    const back = (d: any) => {
        // debugger;
        setActive(d);
    }

    const changeProgress = () => {
        if (activeTab == 'Details') {
            setProgress(0);
        } else if (activeTab == 'PackageSelection') {
            setProgress(35);
        } else if (activeTab == 'Preview') {
            setProgress(70);
        } else if (activeTab == 'Thanks') {
            setProgress(100);
        }
    }

    const checkDetails = () => {
        // debugger;
        setDetailsSubmit(true);
        // setDetailsSubmit((prev) => !prev);
    }

    const validateDetails = () => {
        // debugger;
        let dateValidation ;
        if(advertiseDetails.startValue=='' || advertiseDetails.endValue == '' || advertiseDetails.startValue > advertiseDetails.endValue || advertiseDetails.startValue == advertiseDetails.endValue){
            dateValidation = true;
        }else{
            dateValidation = false;
        }

        if (advertiseDetails.advertiseName != '' && advertiseDetails.startValue != '' && advertiseDetails.endValue != '' && advertiseDetails.ImageName != '' && dateValidation !=true) {
            // debugger;
            setDetailsValidation(false);
            setActive(selectedTab);
            setDetailsSubmit(false);
        } else {
            // debugger;
            setDetailsValidation(true);
        }
    }

    const saveAdvertise = async (d:any) => {
        // debugger;
        // setSubmit(true);
        // if(advertiseDetails.Name !='' && advertiseDetails.FromDate !='' && advertiseDetails.ToDate !='' && advertiseDetails.){
        // }
        // advertiseDetails.ImageName = "";

        

        let data = JSON.stringify(advertiseDetails);
        let packageData = ed.encryptData(data);
        // debugger;
        const responseData: any = await dbCalling.saveAdvertisement(packageData);
        // debugger;
        if (responseData[0].dbResult > 0) {
            // debugger;
            // reset();
            setActive(d)
            closeModel();
           
            if (advertiseDetails.ID == 0) {
                Swal.fire("Success", "Saved Successfully", "success");
            } else if (advertiseDetails.ID > 0) {
                Swal.fire("Success", "Updated Successfully", "success");
            }
            reset();
            viewAdvertisements();
            viewPackages();
        } else if (responseData[0].dbResult == -3) {
            Swal.fire("Opps", "Something went wrong", "info");
        }

    }

    const closeModel = ()=>{
        // debugger;
        const closeButton = document.getElementById('closeModel');
        if (closeButton) {
            closeButton.click();
        }
    }

    const detailsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setAdvertiseDetails((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setAdvertiseDetails((prev) => ({ ...prev, [name]: value }));
        }
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
            // debugger;
            const selectedFile = e.target.files[0];
            // if (selectedFile.size > ((1024 * 1024) * 3)) {
            //     Swal.fire("Opps", "Max Image size is 3MB ", "error");
            // }
            // else {
            setFile(selectedFile);
            // Read the file and convert it to a data URL
            const reader = new FileReader();
            reader.onloadend = () => {
                // debugger;
                setImageSrc(reader.result as string);

                // setAdvertiseDetails((prev) => ({ ...prev, ImageName: reader.result as string }));
            };
            reader.readAsDataURL(selectedFile);
            // debugger;
            // }
        }
    };

    const packageChange = (selectedOption: any) => {
        // debugger;
        setAdvertiseDetails(prev => ({
            ...prev,
            PackageID: selectedOption.value
        }))
        let data: any = packages.filter((x: any) => x.ID == selectedOption.value)
        // debugger;
        setPackageRange(prev => ({
            ...prev,
            RangeFrom: data[0].RangeFrom,
            RangeTo: data[0].RangeTo
        }))
    }

    const viewPackages = async () => {
        // debugger;
        let data = JSON.stringify({ 'userId': userDetails.ID });
        let packageData = ed.encryptData(userDetails.ID);
        const responseData: any = await dbCalling.viewPackages(packageData);
        if(responseData !=undefined){
            setPackages(responseData)
        }
    }

    const viewAdvertisements = async () => {
        // debugger;
        let data = JSON.stringify({ sfDetails: '' });
        let advertiseDetails = ed.encryptData(userDetails.ID);
        // debugger;
        const responseData: any = await dbCalling.viewAdvertisements(advertiseDetails);
        // debugger;
        if(responseData !=undefined ){
            setLstAdvertise(responseData);
            setTempLstAdvertise(responseData);
            
        }
    }

    const deleteAdvertise = (d:any) =>{
        // debugger;
        Swal.fire({
            title: "Do you want to delet the package?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Delete",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                confirmDelete(d);
                //  Swal.fire("Saved!", "", "success");
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
    }

    const confirmDelete = async (d:any) =>{
        // debugger;
        let data = JSON.stringify({'AdvertisementId':d.Advertise_ID});
        let advertiseData = ed.encryptData(d.Advertise_ID);
        const responseData: any = await  dbCalling.deleteAdvertise(advertiseData);
        Swal.fire("Deleted", "Package Deleted Successfully!", "success");
        viewAdvertisements();
    }

    const onChange = (value: any) => {
        // debugger;
        setAdvertiseDetails((prev) => ({
            ...prev,
            Budget: value
        }))
    }

    const reset = () => {
        // debugger;
        setAdvertiseDetails({
            ID: 0,
            advertiseName: '',
            ImageName: '',
            Image: '',
            description: '',
            startValue: '',
            endValue: '',
            RedirectUrl: '',
            PackageID: 0,
            Pincode: 0,
            Budget: 0
        });
        setActive('Details');
        setDetailsSubmit(false);
        setImageSrc('');
    }

    const getImageBase64 = (file: any) => {
        // debugger;
        if (file) {
            // debugger;
            // Read the selected file as a base64 data URL
            const reader = new FileReader();
            reader.onload = (e: any) => {
                // Update the Advertise.imageName with the base64 data URL
                // debugger;
                // this.ImageName = e.target.result;
                let Image = e.target.result;
                setImageSrc(Image as string);
                var logedUser: any;


                let currDatetime: string = new Date().toString().trim();
                currDatetime = currDatetime.replace(/[^a-zA-Z0-9]/g, "")
                let ImageName = "Advertise_" + currDatetime + '_' + userDetails.ID + '.jpeg';

                setAdvertiseDetails(prev => ({
                    ...prev,
                    Image: Image,
                    file: file as Blob,
                    ImageName: ImageName
                }))


            };
            reader.readAsDataURL(file);
        }
    }

    const uploadImage = async (event: any) => {
        debugger;
        const fileInput = event.target as HTMLInputElement;
        const file = fileInput.files?.[0];
        debugger;
        const imgSz :any = await getimgSz(file);
        const imgWgt : any = await getMb(file);
        if(imgSz.width == 1920 && imgSz.height == 1080 && imgWgt <= 2){
            debugger;
            getImageBase64(file);
        }else if(imgSz.width != 1920 && imgSz.height != 1080){
            Swal.fire("Opps", "Image size must be 1920 x 1080", "info");
        }else if(imgWgt > 2){
            Swal.fire("Opps", "Image must be 2Mb", "info");
        }
    }

    const getimgSz = (blob:any) =>{
        debugger;
        return new Promise((resolve, reject) => {
            const img = new Image();
            debugger;
            img.onload = () => {
              resolve({
                width: img.width,
                height: img.height
              });
            };
            debugger;
            img.onerror = (error) => {
              reject(error);
            };
            debugger;
            img.src = URL.createObjectURL(blob);
          });
    }

    const getMb = (blob:any) =>{
        debugger;

        const megabytes = blob.size / (1024*1024);
        return megabytes.toFixed(2);
    }

    const editAdvertise = (d:any) =>{
        
        // debugger;
        setAdvertiseDetails((prev)=>({
            ...prev,
            ID : d.Advertise_ID,
            advertiseName : d.advertiseName,
            ImageName : d.ImageName,
            Image : d.Image,
            description : d.description,
            startValue : d.FromDate, //formatDate(d.FromDate,'dd-mm-YYY'),
            endValue : d.ToDate, //formatDate(d.ToDate,'dd-mm-YYY'),
            RedirectUrl : d.RedirectUrl,
            PackageID : d.PackageID,
            Pincode : d.Pincode,
            Budget : d.Budget,
        }))

        setImageSrc(api.ImageAPIUrl+d.ImageName);   
        // debugger;
        let pacakgesList:any = packages;
        for(let i=0;i<pacakgesList.length;i++){
            // debugger;
            if(pacakgesList[i].ID ==  d.PackageID){
                setPackageRange((prev)=>({
                    ...prev,
                    RangeFrom : pacakgesList[i].RangeFrom,
                    RangeTo : pacakgesList[i].RangeTo
                }))
            }
        }        
    }
    

    const uploadImageBack = async () => {
        // debugger;
        if (file) {
            // debugger;
            const formData = new FormData();
            // debugger;
            formData.append('imageToSave', imageSrc);
            formData.append('file', file);

            let currDatetime: string = new Date().toString().trim();
            currDatetime = currDatetime.replace(/[^a-zA-Z0-9]/g, "");
            let ImageName = "ProfileImage" + currDatetime + '_' + userDetails.ID + '.jpeg';

            setAdvertiseDetails(prev => ({
                ...prev,
                Image: imageSrc,
                file: file as Blob,
                ImageName: ImageName
            }))

            try {
                // debugger;
                const responseData: any = await dbCalling.uploadImage(formData);
                // debugger;
                // debugger;
            }
            catch (error) {
            }
        }
    }

    const searchPackage = (d:any) =>{
        // debugger;
        // lstAdvertise
        let data = templstAdvertise.filter((x:any)=> x.advertiseName.includes(d.target.value) || x.Budget.includes(d.target.value));
        if(data.length > 0){
            setLstAdvertise(data)
        }else{
            setLstAdvertise(templstAdvertise);
        }
    }

    const selectPage = (index:number) =>{
        debugger;
        // let data = pages.reverse();
        debugger;
        if((pages.length - 1) == index){
            debugger;
            let pager : any = [];
            for(let i=pages.length;i<=((pages.length)+5);i++){
                pager.push(i);
            }
            debugger;
            setPages(pager);
        }else{
            setCurrentPage(index);
        }
    }

    const handleNextPage = () => {
        debugger;

        if((tempPage.length-1) == currentPage){
            return;
        }

        if(templstAdvertise.length > 0){

        let totalPages = Math.ceil(templstAdvertise.length / pageLimit);

        debugger;
        // && currentPage !=(pages.length)
        if (currentPage < pages.length ) {
            debugger;
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            debugger;
            const startIndex = (newPage - 1) * pageLimit;
            const endIndex = newPage * pageLimit;
            debugger;
            setLstAdvertise(templstAdvertise.slice(startIndex, endIndex));
        }else{
            debugger;
            let peger : any = [];
            for(let i=(currentPage+1);i<((currentPage+peginationLimit+1));i++){
                if(i < tempPage.length){
                    peger.push(i);
                }
            }
            debugger;
            setPages(peger);
            setCurrentPage(peger[0]);
        }
    }
    }

    const handlePreviousPage = () => {
        debugger;

        if(currentPage == 1){
            return;
        }

        if (currentPage > 1 && pageLimit > currentPage) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            const startIndex = (newPage - 1) * pageLimit;
            const endIndex = newPage * pageLimit;
            setLstAdvertise(templstAdvertise.slice(startIndex, endIndex));
        } else {
            debugger;
           
            let peger : any = [];
            for (let i = currentPage - 1; i >= currentPage - 3 && i >= 0; i--) {
                if (i < tempPage.length) {
                    peger.push(i);
                }
            }
            debugger;
            setPages(peger.reverse());
            
        }
    };


    useEffect(() => {
        // debugger;
        if (activeTab == '') {
            setActive('Details');
        }
        changeProgress();
    }, [activeTab])

    useEffect(() => {
        // debugger;
        validateDetails();
    }, [detailsSubmitted])

    useEffect(() => {
        viewPackages();
    }, [])

    useEffect(() => {
        // debugger;
        setDetailsValidation(false);
    }, [selectedTab])

    useEffect(() => {
        // debugger;
        if (file) {
        }
    }, [file]);

    useEffect(() => {
        // debugger;
        if (imageSrc) {
            //  uploadImage();
        }
    }, [imageSrc])

    useEffect(()=>{
        // debugger;
        viewAdvertisements()
    },[])

    useEffect(()=>{
        // debugger;
        let date = new Date().toISOString().split('T')[0];
        setMinDate(date)
    })

    useEffect(()=>{
        // debugger;
        let pages = templstAdvertise.length / pageLimit;
        // debugger;
        let peginationNumbers : any = [];
        
        for (let i=1;i<=Math.ceil(pages);i++){
            peginationNumbers.push(i)
        };

        setTempPages(peginationNumbers);
        setStartIndex((1-1*pageLimit));
        setEndIndex((0+pageLimit));
        handleNextPage();
    },[templstAdvertise])



    useEffect(()=>{
        debugger;
        let peger : any = [];
        for(let i=1;i<=peginationLimit;i++){
            peger.push(i);
        }
        setPages(peger);
    },[tempPage])

    useEffect(()=>{
        debugger;
        const newPage = currentPage;
        // setCurrentPage(newPage);
        debugger;
        const startIndex = (newPage - 1) * pageLimit;
        const endIndex = newPage * pageLimit;
        debugger;
        setLstAdvertise(templstAdvertise.slice(startIndex, endIndex));
    },[currentPage])

    return {
        // Functions
        signup, openTab, saveAdvertise, checkDetails, detailsChange, handleImageChange, back, packageChange, onChange,
        uploadImage,deleteAdvertise,reset,closeModel,editAdvertise,searchPackage,selectPage,handleNextPage,handlePreviousPage

        // states
        , activeTab, packages, progress, advertiseDetails, detailsSubmitted, validDetails, imageSrc, packageOptions, 
        lstMerchantDrp, packageRanges, selectedTab,lstAdvertise,minDate,pages,currentPage,tempPage
    };
}