export function Api (){

    const APIUrl='http://49.206.197.126:44335/api/';
    const ImageAPIUrl='http://49.206.197.126:44335/Images/';
    const VideoAPIUrl='http://49.206.197.126:44335/Videos/';

    // const APIUrl='http://192.168.0.177:44335/api/';
    // const ImageAPIUrl='http://192.168.0.177:44335/Images/';
    // const VideoAPIUrl='http://192.168.0.177:44335/Videos/';
    
    const EmailUrl='https://mechknowsamplework.com/ChartTwits/ForgotPasswordEmail.php';
    const EncryptionKey ='150150mechknow46';
    const EncryptionIV ='1501504646150150';

    return {
        APIUrl,ImageAPIUrl,VideoAPIUrl,EmailUrl,EncryptionKey,EncryptionIV
    }
}