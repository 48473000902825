import { SignupModel } from "./SignupModel";


const Signup: React.FC = () => {

    const signupModel = SignupModel();

    return (
        <div className="app">
            <div className="login-area su">
                <div className="container">
                    <div className="login-box ptb--100">
                        <div className="login_form">
                            <div className="login-form-head" >
                                <h4>Sign up</h4>
                                <p>And get started on your advertisement.</p>
                            </div>
                            <div className="login-form-body">
                                {/* <div className="row"> */}
                                {/* <div className="col-md-6"> */}
                                <div className="form-gp">
                                    <label >First name</label>
                                    <input type="text" id="exampleInputName1" name="FirstName"
                                        onChange={signupModel.valueChange}
                                        className={(signupModel.form.FirstName) == '' && (signupModel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-user"></i>
                                    <div className="text-danger"></div>
                                </div>
                                {/* </div>
                                    <div className="col-md-6"> */}
                                <div className="form-gp">
                                    <label >Last name</label>
                                    <input type="text" id="exampleInputName1" name="LastName"
                                        onChange={signupModel.valueChange}
                                        className={(signupModel.form.LastName) == '' && (signupModel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-user"></i>
                                    <div className="text-danger"></div>
                                </div>
                                {/* </div> */}
                                {/* </div> */}
                                <div className="form-gp">
                                    <label >Email address</label>
                                    <input type="email" id="exampleInputEmail1" name="Email"
                                        onChange={signupModel.valueChange}
                                        className={(signupModel.form.Email) == '' && (signupModel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-email"></i>
                                    {
                                        signupModel.submitted && !signupModel.isValidEmail(signupModel.form.Email) ?
                                        <>
                                        <div className="text-danger">Invalid Email</div>
                                        </> : <></>
                                    }
                                </div>
                                <div className="form-gp">
                                    <label >Organisation</label>
                                    <input type="email" id="exampleInputEmail1" name="Organisation" onChange={signupModel.valueChange} />
                                    <i className="ti-world"></i>
                                    <div className="text-danger"></div>
                                </div>
                                <div className="form-gp">
                                    <label >Contact no</label>
                                    <input type="number" id="exampleInputEmail1" name="ContactNo"
                                        onChange={signupModel.valueChange}
                                        value={signupModel.form.ContactNo}
                                        className={(signupModel.form.ContactNo) == '' && (signupModel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-mobile"></i>
                                    <div className="text-danger"></div>
                                </div>
                                <div className="form-gp">
                                    <label>Password</label>
                                    <input type="text" id="exampleInputPassword1" name="Password"
                                        onChange={signupModel.valueChange}
                                        className={(signupModel.form.Password) == '' && (signupModel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-lock"></i>
                                    <div className="text-danger"></div>
                                </div>
                                <div className="form-gp">
                                    <label>Confirm password</label>
                                    <input type="text" id="exampleInputPassword2" name="ConfirmPassword"
                                        onChange={signupModel.valueChange}
                                        className={(signupModel.form.ConfirmPassword) == '' && (signupModel.submitted) ? 'invalid-bottom' : ''} />
                                    <i className="ti-lock"></i>
                                    {
                                        signupModel.submitted && signupModel.form.Password !==  signupModel.form.ConfirmPassword ?
                                        <>
                                        <div className="text-danger">Password Mismatch</div>
                                        </> : <></>
                                    }
                                </div>
                                <div className="submit-btn-area">
                                    <button id="form_submit" onClick={signupModel.signUp}>Submit <i className="ti-arrow-right"></i></button>
                                </div>
                                <div className="form-footer text-center mt-5">
                                    <p className="text-muted">Already have an account? <a href="login">Sign in</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup;