import { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import { EncryptDecrypt } from "../../services/EncryptDecrypt";
import Swal from "sweetalert2";
import { DatabaseCalling } from "../../services/DatabaseCalling";
import { useSelector } from "react-redux";
import { AppState } from "../../store/Page.Actions";

export function PackageModel(){

    const dbCalling = DatabaseCalling();
    const ed = EncryptDecrypt();
    const userDetails :any = useSelector((state: AppState) => state.storeData.loggedUser);

    const [adPackage,setPackage] = useState({
        ID : 0,
        Name : '',
        RangeFrom : 0,
        RangeTo: 0,
        ValidDays : 0,
        Description : '',
        CreatedDate : '',
        UpdatedDate : '',
    })

    const [lstPackages,setPackages] = useState([{
        ID:0,
        Name:"",
        RangeFrom:0,
        RangeTo:0,
        ValidDays:0,
        Description:"",
        CreatedDate:"",
        UpdatedDate:"",
    }]);

   

    const [submitted,submitPackage] = useState(false);

    useEffect(()=>{
        viewPackages();
    },[])

    const packageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value , type  } = e.target as HTMLInputElement | HTMLTextAreaElement;
            debugger;
            const newValue = type === 'number' ? (+value) : value;
            setPackage((prev) => ({ ...prev, [name]: newValue }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setPackage((prev) => ({ ...prev, [name]: value }));
        }
    }

    const savePackage = async () =>{
        debugger;
        submitPackage(true);

        let range = false;

        if( adPackage.RangeFrom !=0 && adPackage.RangeTo !=0){
            debugger;
            range = false;
        }
         if(adPackage.RangeFrom > adPackage.RangeTo ){
            debugger;
            range = true;
        }
        debugger;
        if(adPackage.Name !='' &&  adPackage.ValidDays !=0 && !range ){
            debugger;
            adPackage.UpdatedDate = adPackage.UpdatedDate.replace('T',' ');
            debugger;
            let data = JSON.stringify(adPackage);
            let packageData = ed.encryptData(data);
            debugger;
            const responseData: any = await  dbCalling.savePackage(packageData);
            debugger;
            if(responseData[0].dbResult > 0){
                debugger;
                reset();
                viewPackages();
                if(adPackage.ID == 0){
                    Swal.fire("Success", "Saved Successfully", "success");
                }else if(adPackage.ID > 0){
                    Swal.fire("Success", "Updated Successfully", "success");
                }
            }else if(responseData[0].dbResult == -3){
                Swal.fire("Opps", "Package name already exists!", "info");
            }
        }
    }

    const viewPackages = async () =>{
        debugger;
        let data = JSON.stringify({'userId':userDetails.ID});
        let packageData = ed.encryptData(userDetails.ID);
        const responseData: any = await  dbCalling.viewPackages(packageData);
        setPackages(responseData)
    }

    const editPackage = async (d:any)=>{
        debugger;
        setPackage(d);
    }

    const deletePackage = async (d:any)=>{
        debugger;

        Swal.fire({
            title: "Do you want to delet the package?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Delete",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                confirmDelete(d);
                //  Swal.fire("Saved!", "", "success");
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
    }

    const confirmDelete = async (d:any) =>{
        debugger;
        let data = JSON.stringify({'PackageID':d.ID});
        let packageData = ed.encryptData(d.ID);
        const responseData: any = await  dbCalling.deletePackage(packageData);
        Swal.fire("Deleted", "Package Deleted Successfully!", "success");
        viewPackages();
    }

    const reset=()=>{
        submitPackage(false);
        setPackage({
            ID : 0,
            Name : '',
            RangeFrom : 0,
            RangeTo: 0,
            ValidDays : 0,
            Description : '',
            CreatedDate : '',
            UpdatedDate : '',
        })
    }

    return{adPackage,submitted,packageChange,savePackage,lstPackages,editPackage,deletePackage}
}