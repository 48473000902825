import CryptoJS from 'crypto-js';
import { Api } from './Api';
export function EncryptDecrypt (){

    const api = Api();

    const key = CryptoJS.enc.Utf8.parse(api.EncryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(api.EncryptionIV);

    const encryptData =(data:any)=> {
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });  
        return  encrypted.toString();

        // const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), api.EncryptionKey).toString();
        // return encrypted;
    }

    const decryptData = (data:any) => {
        debugger;
        var decrypted = CryptoJS.AES.decrypt(data, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
        // const encrypted:any = localStorage.getItem(name);
        // const decrypted = CryptoJS.AES.decrypt(encrypted, api.EncryptionKey).toString(CryptoJS.enc.Utf8);
        // return JSON.parse(decrypted);
    }

    return {encryptData,decryptData}
}