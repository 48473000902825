import React from 'react';
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login/LoginView';
import Signup from './components/Signup/SignupView';
import AdvertiseView from './components/Advertise/AdvertiseView';
import PackageView from './components/Package/PackageView';
import { useSelector } from 'react-redux';
import { AppState } from './store/Page.Actions';

const App: React.FC = () => {
  const details: any = useSelector((state: AppState) => state.storeData.loggedUser);
  debugger;

  debugger;
  return (
    <div className="app">
      <Router>
        <Routes>
          {
            details ?
              <>
                {details != '' && details.ID > 0 ? (
                  <>
                    <Route path="/" element={<AdvertiseView />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/sign-up" element={<Signup />} />

                    {/* Components */}
                    <Route path="/create-advertise" element={<AdvertiseView />} />
                    <Route path="/package" element={<PackageView />} />
                  </>
                ) :
                 <>
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/sign-up" element={<Signup />} />
                 </>
                }
              </>
              :
              <>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/sign-up" element={<Signup />} />
              </>
          }

          {/* {details !='' && details.ID > 0 ? (
            <>
              <Route path="/" element={<AdvertiseView />} />
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<Signup />} />
              <Route path="/create-advertise" element={<AdvertiseView />} />
              <Route path="/package" element={<PackageView />} />
            </>
            )
            : 
            (
             <>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
             </>
            )
          } */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
