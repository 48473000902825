import { useEffect, useState } from "react";
import { DatabaseCalling } from "../../services/DatabaseCalling";
import { EncryptDecrypt } from "../../services/EncryptDecrypt";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { ActionType } from "../../store/Page.Actions";



export function LoginModel(){

    const dbCalling = DatabaseCalling();
    const userDetails = useSelector((state: any) => state.viewName);

    const navigate = useNavigate();
    const ed = EncryptDecrypt();
    const dispatch = useDispatch();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const [login,setLogin] = useState({
        Email : '',
        Password : ''
    })

    const [submitted,setSubmit] = useState(false);

    const signup = ()=>{
        debugger;
        navigate('/sign-up');
    }

    const valueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setLogin((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setLogin((prev) => ({ ...prev, [name]: value }));
        }
    }

    const signin = async ()=>{
        debugger;
        setSubmit(true);
        let validateEmail;

        if(isValidEmail(login.Email)){
            validateEmail = false;
        }else{
            validateEmail = true;
        }

        if(login.Email !='' && login.Password !=''){
            debugger;
            let data = JSON.stringify(login);
            let loginData = ed.encryptData(data);
            const responseData: any = await  dbCalling.login(loginData);
            debugger;

            if(responseData !=undefined && responseData[0].ID > 0){
                debugger;   
                // responseData[0];
                dispatch({
                    type: ActionType.STORE,
                    payload: {
                        storeData:responseData[0],
                        viewName:'loggedUser',
                    },
                  });
                // reset();
                // GetDetails();
                navigate('/create-advertise');
            }else if(responseData[0].dbResult == -3){
                Swal.fire("Invalid User", "", "error");
            }
            // else if(){

            // }
            // reset();
        }
    }


    
    const isValidEmail = (email:any) =>{
        debugger;
        return emailRegex.test(email);
    }


//    const GetDetails = () =>{
//         debugger;
//         // console.log(userDetails);
//     }


    const reset = () =>{
        setLogin({
            Email : '',
            Password : ''
        })
    }

    // useEffect(()=>{
    //     debugger;
    //     if(userDetails.ID > 0){
    //         debugger;
    //         navigate('/package');
    //     }
    // },[userDetails])

    return{
        // functions
        signup,valueChange,signin,isValidEmail

        // states
        ,login,submitted
    };
}


